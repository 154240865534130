import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

import ApiCity from 'types/api/City';
import { ApiResources } from 'types/apiResources';
import { Locales } from 'types/locale';
import { ActionResponse } from 'types/redux/ActionResponse';

import City from 'models/City';

import { select as selectLocale } from 'utils/locale';

import AsyncSelect from 'components/layout/forms/AsyncSelect';

import { Props } from './index';
import StyledComponent from './styles';

const AdminModuleCitiesSelect: FunctionComponent<Props> = ({ selectProps, overrideQuery, actions }) => {
    const translations = selectLocale({
        [Locales.Pl]: require('./locales/pl.json'),
    });

    const customOptionStyle = ({ innerProps, innerRef, value: { name, aditionalName }, ...props }): ReactNode =>{
        const { isSelected } = props;
        return(
            <div
                ref={innerRef}
                {...innerProps}
                className={classnames({
                    'custom-cities-option': true,
                    'is-selected': isSelected,
                })}
            >
                <div className="city-info">
                    <p className="city-info-name">{name}</p>
                    <div className="city-info-aditional">{aditionalName}</div>
                </div>
            </div>
        );};

    return (
        <StyledComponent className='public-module-cities-select'>
            <AsyncSelect
                placeholder={translations?.form?.inputs?.select?.placeholder}
                label={translations?.form?.inputs?.select?.label}
                onLoad={(query: string) => actions.suggest({
                    keyword: query,
                    page: 1,
                    perPage: 20,
                    ...overrideQuery,
                }, {
                    ignoreState: true,
                })}
                onMapResponse={(response: ActionResponse) => response.payload.data[ApiResources.Cities].elements}
                onMapOption={(element: ApiCity) => {
                    const model = new City(element);
                    return model.getOption();
                }}
                customSelect={true}
                components={{ Option: customOptionStyle }}
                {...selectProps}
            />
        </StyledComponent>
    );
};

export default AdminModuleCitiesSelect;
