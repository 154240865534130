import { Endpoints, ListParamsDefault } from 'types/endpoints';
import { ActionResponse } from 'types/redux/ActionResponse';
import { ReduxRequestPassOptions } from 'types/request';

import { withVariables } from 'utils/string';
import { reduxRequest as request } from 'services/Api';

import * as types from './types';

export interface SuggestParams extends ListParamsDefault {
    keyword?: string;
}
export const suggest = (params?: SuggestParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.LIST,
            method: 'GET',
            path: withVariables(Endpoints.PublicCitiesSuggest, params),
            params,
            requestParams: params,
            ...requestParams,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};


export interface ListParams extends ListParamsDefault {
    stateId?: string;
}
export const list = (params?: ListParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.LIST,
            method: 'GET',
            path: withVariables(Endpoints.PublicCities, params),
            params,
            requestParams: params,
            ...requestParams,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface SingleParams {
    id: string;
}
export const single = (params: SingleParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.SINGLE,
            method: 'GET',
            path: withVariables(Endpoints.PublicCity, params),
            params,
            ...requestParams,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};
