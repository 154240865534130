import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ActionResponse } from 'types/redux/ActionResponse';
import { ReduxRequestPassOptions } from 'types/request';

import { suggest, SuggestParams } from 'store/modules/cities/actions';

import { Props as AsyncSelectProps } from 'components/layout/forms/AsyncSelect';

import Component from './component';

export interface Props extends ParentProps {
    actions: {
        suggest: (params?: SuggestParams, requestParams?: ReduxRequestPassOptions) => Promise<ActionResponse>;
    };
}

export interface ParentProps {
    overrideQuery?: SuggestParams;
    selectProps?: AsyncSelectProps;
    error?: string;
}

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            suggest: bindActionCreators(suggest, dispatch),
        },
    };
};

Component.defaultProps = {
    overrideQuery: {},
    selectProps: {
        name: 'cityId',
        onChange: () => null,
        onLoad: () => null,
        onMapOption: () => null,
        onMapResponse: () => null,
    },
};
export default connect(null, mapDispatchToProps)(Component);
