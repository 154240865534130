import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    [class$=container] {
        width: 100%;

        [class$=control] {
            width: 100%;
            height: 3.5em;
            border: none;
            padding: 0 1em;
            color: ${vars.colorText};
            border-radius: .5em;
            font-size: .9em;

            [class$=placeholder] {
                color: ${vars.colorTextLight};
            }
        }

        [class$=menu] {
            z-index: 99;
            cursor: default;
        }
    }

    .error-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: .5em;
        margin-top: .5em;

        .error-icon {
            margin-right: .5em;
            max-width: 100%;
        }
        .error-message {
            font-size: .8em;
            color: #FF0000;
        }
    }

    &.style-secondary {
        [class$=container] {
            width: 100%;

            [class$=control] {
                background: #FFFFFF;
                box-shadow: none;
                height: 3em;
                border-radius: 0;

                [class$=ValueContainer] {
                    [class$=singleValue] {
                        color: #000000;
                    }
                }

                [class$=singleValue] {
                    color: #000000;
                }

                [class$=IndicatorsContainer] {
                    [class$=indicatorSeparator] {
                        display: none;
                    }
                    [class$=indicatorContainer] {
                        color: #000000;
                    }
                }

                [class$=placeholder] {
                    color: #000000;
                }
            }

            [class$=menu] {
                z-index: 99;

                [class$=MenuList] {
                    [class$=option] {
                        color: #000000;
                    }
                }
            }
        }
    }

    &.style-outline {
        [class$=container] {
            width: 100%;

            [class$=control] {
                background: #FFFFFF;
                box-shadow: none;
                height: 3.2em;
                border: 0.06em solid ${vars.colorBorder};
                border-radius: 0.5em;

                [class$=ValueContainer] {
                    [class$=singleValue] {
                        color: #000000;
                    }
                }

                [class$=singleValue] {
                    color: #000000;
                }

                [class$=IndicatorsContainer] {
                    [class$=indicatorSeparator] {
                        display: none;
                    }
                    [class$=indicatorContainer] {
                        color: #000000;
                    }
                }

                [class$=placeholder] {
                    color: #000000;
                }
            }

            [class$=menu] {
                z-index: 99;

                [class$=MenuList] {
                    [class$=option] {
                        color: #000000;
                    }
                }
            }
        }
    }

    &.disabled {
        [class$=container] {
            [class$=control] {
                background-color: #d7e2e4;
            }
        }
    }

    &.error {
        [class$=container] {
            [class$=control] {
                border: .1em solid #FF0000 !important;
            }
        }
    }

    /* &.decorator-left {
        [class$=container] {
            [class$=control] {
                padding-left: 2.5em;
            }
        }
    } */
    &.decorator-right {
        [class$=container] {
            [class$=control] {
                padding-right: 2.5em;
            }
        }
    }
`;
