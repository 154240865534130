import { FunctionComponent, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

import { Option } from 'types/options';

import InputWrapper, { ValidationActions } from 'components/layout/forms/InputWrapper';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsAsyncSelect: FunctionComponent<Props> = ({
    formik,
    name,
    label,
    placeholder,
    helper,
    value,
    onChange,
    disabled,
    style,
    styles,
    defaultOptions,
    onLoad,
    onMapResponse,
    onMapOption,
    onSetOptions,
    selectProps,
    loadTimestamp,
    validationAction,
    decoratorLeft,
    decoratorRight,
    onCreate,
    customSelect,
    components,
    error,
}) => {
    const [preloadedOptions, setPreloadedOptions]: [any, Function] = useState([]);
    let loadTimeout = null;

    const errorMessage = error || formik?.errors[name]?.toString() || null;
    let selectedDefaultOptions = defaultOptions.length > 0 && preloadedOptions.length === 0
        ? defaultOptions
        : preloadedOptions;
    selectedDefaultOptions = onSetOptions(selectedDefaultOptions);
    const [isFocused, setIsFocused]: [boolean, Function] = useState(false);


    useEffect(() => {
        onDynamicLoad()
            .then((options: Option<any>[]) => {
                setPreloadedOptions(options);
            })
            .catch(() => {
                setPreloadedOptions([]);
            });
    }, [loadTimestamp]);

    useEffect(() => {
        function onKeyup(e) {
            if (e.key === 'Tab') {
                onCreate && onCreate();
            }
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [selectedDefaultOptions, onCreate]);

    const onDynamicLoad = (input: string = ''): Promise<any> => {
        clearTimeout(loadTimeout);
        return new Promise(resolve => {
            loadTimeout = setTimeout(() => {
                return resolve(
                    onLoad(input)
                        .then(response => {
                            if (!response) return [];

                            const elements = onMapResponse(response);
                            return elements
                                .map((element: any) => onMapOption(element))
                                .filter((element: any) => element);
                        })
                        .catch(() => ([]))
                );
            }, 250);
        });
    };

    if (typeof value === 'string') {
        value = selectedDefaultOptions.find(selectedDefaultOption => selectedDefaultOption.value === value) || value;
    }

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-async-select',
                [
                    `style-${style}`,
                    `name-${name}`,
                ],
                {
                    'error': Boolean(error),
                    'disabled': Boolean(disabled),
                    'decorator-left': Boolean(
                        error && validationAction === ValidationActions.Decorator ||
                        decoratorLeft && decoratorLeft.visible !== false
                    ),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                }
            )}
            style={styles}
        >
            <InputWrapper
                name={name}
                label={label}
                helper={helper}
                error={error}
                disabled={disabled}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
                isFocused={isFocused}
            >
                {
                    customSelect ? (
                        <AsyncSelect
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            aria-label={label}
                            onChange={(option: Option<any>) => {
                                formik?.setFieldError(name, null);
                                onChange(option);
                            }}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            isDisabled={disabled}
                            loadOptions={onDynamicLoad}
                            defaultOptions={selectedDefaultOptions}
                            cacheOptions={false}
                            ignoreAccents={false}
                            noOptionsMessage={() => 'Brak wyników'}
                            loadingMessage={() => 'Pobieram dane...'}
                            isClearable={true}
                            id={name}
                            instanceId={name}
                            components={components}
                            {...selectProps}
                        />
                    ) : (
                        <AsyncSelect
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            aria-label={label}
                            onChange={(option: Option<any>) => {
                                formik?.setFieldError(name, null);
                                onChange(option);
                            }}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            isDisabled={disabled}
                            loadOptions={onDynamicLoad}
                            defaultOptions={selectedDefaultOptions}
                            cacheOptions={false}
                            ignoreAccents={false}
                            noOptionsMessage={() => 'Brak wyników'}
                            loadingMessage={() => 'Pobieram dane...'}
                            isClearable={true}
                            id={name}
                            instanceId={name}
                            {...selectProps}
                        />
                    )
                }
            </InputWrapper>
            {errorMessage && errorMessage.trim() && (
                <div className="error-container">
                    <img
                        className="error-icon"
                        src="/images/icons/error.svg"
                        alt="error-icon"
                        loading="lazy"
                    />
                    <div
                        className="error-message"
                        dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutFormsAsyncSelect;
