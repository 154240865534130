import { connect, FormikContextType } from 'formik';

import { Option } from 'types/options';

import { Props as InputWrapperProps } from 'components/layout/forms/InputWrapper';

import Component from './component';

export interface Props extends InputWrapperProps {
    formik?: FormikContextType<any>;
    name: string;
    placeholder?: string;
    value?: string | number | Option<any>;
    onChange: (option: Option<any>) => any;
    style?: InputStyles;
    styles?: object;
    error?: string;
    disabled?: boolean;
    customSelect?: boolean;
    components?: any;

    defaultOptions?: Option<any>[];
    onLoad: (input: string) => Promise<any>;
    loadTimestamp?: number;
    onMapResponse: (response: object) => any[];
    onMapOption: (element: any) => any;
    onSetOptions?: (options: Option<any>[]) => Option<any>[];
    selectProps?: object;
    onCreate?: () => any;
}

export interface State {
    preloadedOptions: Option<any>[];
}


export enum InputStyles {
    Primary = 'primary',
    Secondary = 'secondary',
    Outline = 'outline',
}

Component.defaultProps = {
    placeholder: null,
    value: '',
    error: null,
    disabled: false,
    style: InputStyles.Primary,
    styles: {},

    defaultOptions: [],
    loadTimestamp: null,
    onSetOptions: (options) => options,
    selectProps: {},
    onCreate: null,
};


export default connect(Component);