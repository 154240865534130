import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	max-height: 100%;

	.layout-forms-input-wrapper {
		margin-bottom: 0;
	}

	.async-select__value-container,
	.async-select__value-container--has-value,
	.async-select__menu {
		text-transform: capitalize;
	}

	.custom-cities-option {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 0.3em 0.3em 0.3em 1.5em;
		gap: 0.5em;
		margin-bottom: 0.2em;
		&.is-selected {
			background-color: #10F5B0;
		}
		.city-info {
			.city-info-name {
				font-weight: 600;
			}
			.city-info-aditional {
				font-size: 0.8em;
				text-transform: capitalize;
			}
		}
	}

	.style-outline {
		.layout-forms-input-wrapper {
			.label-wrapper {
				.label-body {
					.layout-forms-input-label {
						opacity: 1;
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.custom-cities-option {
			display: flex;
			justify-content: flex-start;
			text-align: left;
			padding: 0.4em 0.4em 0.4em 1.2em;
			gap: 0.5em;
			margin-bottom: 0.2em;

			.city-info {
				.city-info-name {
					font-weight: 600;
					font-size: 1.2em;
				}
				.city-info-aditional {
					font-size: 1.1em;
					text-transform: capitalize;
				}
			}
		}
	}

	@media all and (max-width: ${vars.mobileM}) {
		.style-outline {
			margin: 0.5em 0 2em 0;
			.layout-forms-input-wrapper {
				.label-wrapper {
					.label-body {
						.layout-forms-input-label {
							opacity: 1;
							font-size: 1.2em;
						}
					}
				}
				.internal-wrapper {
					font-size: 1.5em;
				}
			}
		}
	}
`;
